// BottomBanner.js
import React, { useState } from 'react';
import './BottomBanner.css';
import bannerImage from './amazon-todays-deal-banner-expand-4.jpg'; // Replace with the actual path to your image
import { MdClose } from 'react-icons/md'; // Import a close icon (optional)
import { getAnalytics, logEvent } from 'firebase/analytics';

const BottomBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = (event) => {
    setIsVisible(false);
    logEvent(getAnalytics(), 'close_banner_clicked', {
      time: new Date().toISOString()
  })
  };

  const handleBannerClick = () => {
    logEvent(getAnalytics(), 'banner_clicked', {
      time: new Date().toISOString()
  })
  };

  if (!isVisible) return null;

  return (
    <a
      href="https://amzn.to/3YX04W3"
      target="_blank"
      rel="noopener noreferrer"
      className="bottom-banner"
      onClick={handleBannerClick}
    >
      <img src={bannerImage} alt="Banner" />
      <button className="close-button" onClick={handleClose}>
        <MdClose size={24} />
      </button>
    </a>
  );
};

export default BottomBanner;
