import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Header';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import AccountPopup from './AccountPopup';
import ImageEditor from './ImageEditor';
import Meme from './Meme';
import { Provider } from 'react-redux';
import store from './store'; 
import Faq from './Faq';
import Success from './Success';
import { Helmet } from 'react-helmet';
import MemeFeed from './MemeFeed';
import Footer from './Footer';
import './App.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import BottomBanner from './BottomBanner';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppContent() {
  const [index, setIndex] = useState(0);
  const [showFooter, setShowFooter] = useState(true);
  const location = useLocation();

  const handleTabChange = (index) => {
    setIndex(index);
  };

  return (
    <div className="App">
      <Helmet>
        <title>AI Meme Generator</title>
        <meta name="description" content="Experience the best AI-powered meme generator. Generate hilarious memes super fast"/>
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="AI Meme Generator" />
        <meta property="og:url" content="https://memehaha.lol" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <ScrollToTop />

      {/* Conditionally render tabs only on the home page */}
      {location.pathname === '/' && (
        <Tabs selectedIndex={index} onSelect={handleTabChange}>
          <TabList className="full-width-tablist sticky-tablist">
          <Tab className="full-width-tab">Custom</Tab>
            <Tab className="full-width-tab">Template</Tab>
          </TabList>
          <TabPanel>
            <ImageEditor setShowFooter={setShowFooter} />
          </TabPanel>
          <TabPanel>
            <Meme setShowFooter={setShowFooter} />
          </TabPanel>
        </Tabs>
      )}

      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/success" element={<Success />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/latest-memes" element={<MemeFeed setShowFooter={setShowFooter} />} />
        <Route path="/drake-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="drake" />} />
        <Route path="/distracted-boyfriend-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="db" />} />
        <Route path="/galaxy-brain-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="gb" />} />
        <Route path="/first-world-problems-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="fwp" />} />
        <Route path="/change-my-mind-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="cmm" />} />
        <Route path="/is-this-a-pigeon-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="pigeon" />} />
        <Route path="/futurama-fry-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="fry" />} />
        <Route path="/kermit-the-frog-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="kermit" />} />
        <Route path="/roll-safe-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="rollsafe" />} />
        <Route path="/ancient-alien-guy-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="aag" />} />
        <Route path="/its-a-trap-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="ackbar" />} />
        <Route path="/too-afraid-to-ask-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="afraid" />} />
        <Route path="/agnes-harnes-winking-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="agnes" />} />
        <Route path="/agnes-harnes-winking-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="agnes" />} />
        <Route path="/nobody-got-time-for-that" element={<Meme setShowFooter={setShowFooter} defaultTemplate="aint-got-time" />} />
        <Route path="/awkward-moment-seal" element={<Meme setShowFooter={setShowFooter} defaultTemplate="ams" />} />
        <Route path="/ants-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="ants" />} />
        <Route path="/redneck-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="apcr" />} />
        <Route path="/always-has-been-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="astronaut" />} />
        <Route path="/and-then-i-said-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="atis" />} />
        <Route path="/life-finds-a-way" element={<Meme setShowFooter={setShowFooter} defaultTemplate="away" />} />
        <Route path="/socially-awesome-penguin" element={<Meme setShowFooter={setShowFooter} defaultTemplate="awesome" />} />
        <Route path="/socially-awkward-penguin" element={<Meme setShowFooter={setShowFooter} defaultTemplate="awkward" />} />
        <Route path="/socially-awesome-awkward-penguin" element={<Meme setShowFooter={setShowFooter} defaultTemplate="awesome-awkward" />} />
        <Route path="/socially-awkward-awesome-penguin" element={<Meme setShowFooter={setShowFooter} defaultTemplate="awkward-awesome" />} />
        <Route path="/you-should-feel-bad-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="bad" />} />
        <Route path="/bad-choice-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="badchoice" />} />
        <Route path="/running-away-balloon-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="balloon" />} />
        <Route path="/men-in-black-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="because" />} />
        <Route path="/blackjack-and-hookers" element={<Meme setShowFooter={setShowFooter} defaultTemplate="bender" />} />
        <Route path="/it-aint-much-but-its-honest-work" element={<Meme setShowFooter={setShowFooter} defaultTemplate="bihw" />} />
        <Route path="/bilbo-baggins-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="bilbo" />} />
        <Route path="/bad-luck-brian" element={<Meme setShowFooter={setShowFooter} defaultTemplate="blb" />} />
        <Route path="/buy-a-boat-cat" element={<Meme setShowFooter={setShowFooter} defaultTemplate="boat" />} />
        <Route path="/bongo-cat" element={<Meme setShowFooter={setShowFooter} defaultTemplate="bongo" />} />
        <Route path="/why-not-both" element={<Meme setShowFooter={setShowFooter} defaultTemplate="both" />} />
        <Route path="/whats-in-the-box" element={<Meme setShowFooter={setShowFooter} defaultTemplate="box" />} />
        <Route path="/everywhere-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="buzz" />} />
        <Route path="/office-space-milton" element={<Meme setShowFooter={setShowFooter} defaultTemplate="cake" />} />
        <Route path="/im-the-captain-now" element={<Meme setShowFooter={setShowFooter} defaultTemplate="captain" />} />
        <Route path="/captain-america-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="captain-america" />} />
        <Route path="/confession-bear-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="cb" />} />
        <Route path="/communist-bugs-bunny" element={<Meme setShowFooter={setShowFooter} defaultTemplate="cbb" />} />
        <Route path="/comic-book-guy" element={<Meme setShowFooter={setShowFooter} defaultTemplate="cbg" />} />
        <Route path="/captain-hindsight" element={<Meme setShowFooter={setShowFooter} defaultTemplate="ch" />} />
        <Route path="/american-chopper-argument" element={<Meme setShowFooter={setShowFooter} defaultTemplate="chair" />} />
        <Route path="/cheems-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="cheems" />} />
        <Route path="/you-were-the-chosen-one" element={<Meme setShowFooter={setShowFooter} defaultTemplate="chosen" />} />
        <Route path="/change-my-mind" element={<Meme setShowFooter={setShowFooter} defaultTemplate="cmm" />} />
        <Route path="/what-a-country" element={<Meme setShowFooter={setShowFooter} defaultTemplate="country" />} />
        <Route path="/crazy-pills" element={<Meme setShowFooter={setShowFooter} defaultTemplate="crazypills" />} />
        <Route path="/get-better-material" element={<Meme setShowFooter={setShowFooter} defaultTemplate="crow" />} />
        <Route path="/expectation-vs-reality-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="dbg" />} />
        <Route path="/distracted-girlfriend-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="dg" />} />
        <Route path="/doge-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="doge" />} />
        <Route path="/dragon-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="dragon" />} />
        <Route path="/high-five-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="drowning" />} />
        <Route path="/daily-struggle" element={<Meme setShowFooter={setShowFooter} defaultTemplate="ds" />} />
        <Route path="/dwight-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="dwight" />} />
        <Route path="/you-sit-on-a-throne-of-lies" element={<Meme setShowFooter={setShowFooter} defaultTemplate="elf" />} />
        <Route path="/drifting-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="exit" />} />
        <Route path="/forever-alone-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="fa" />} />
        <Route path="/facepalm-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="facepalm" />} />
        <Route path="/frog-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="fbf" />} />
        <Route path="/feels-good-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="feelsgood" />} />
        <Route path="/stop-trying-to-make-fetch-happen" element={<Meme setShowFooter={setShowFooter} defaultTemplate="fetch" />} />
        <Route path="/this-is-fine-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="fine" />} />
        <Route path="/are-you-two-friends-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="friends" />} />
        <Route path="/confused-gandalf-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="gandalf" />} />
        <Route path="/grinds-my-gear" element={<Meme setShowFooter={setShowFooter} defaultTemplate="gears" />} />
        <Route path="/good-guy-greg" element={<Meme setShowFooter={setShowFooter} defaultTemplate="ggg" />} />
        <Route path="/spiderman-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="glasses" />} />
        <Route path="/and-its-gone-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="gone" />} />
        <Route path="/grave-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="grave" />} />
        <Route path="/grus-plan" element={<Meme setShowFooter={setShowFooter} defaultTemplate="gru" />} />
        <Route path="/grumpy-cat" element={<Meme setShowFooter={setShowFooter} defaultTemplate="grumpycat" />} />
        <Route path="/hagrid-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="hagrid" />} />
        <Route path="/its-happening-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="happening" />} />
        <Route path="/harold-hide-the-pain" element={<Meme setShowFooter={setShowFooter} defaultTemplate="harold" />} />
        <Route path="/headache-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="headaches" />} />
        <Route path="/i-can-has-cheezburger" element={<Meme setShowFooter={setShowFooter} defaultTemplate="icanhas" />} />
        <Route path="/inigo-montoya" element={<Meme setShowFooter={setShowFooter} defaultTemplate="inigo" />} />
        <Route path="/the-most-interesting-man-in-the-world" element={<Meme setShowFooter={setShowFooter} defaultTemplate="interesting" />} />
        <Route path="/insanity-wolf" element={<Meme setShowFooter={setShowFooter} defaultTemplate="iw" />} />
        <Route path="/joseph-ducreux" element={<Meme setShowFooter={setShowFooter} defaultTemplate="jd" />} />
        <Route path="/nothing-to-do-here" element={<Meme setShowFooter={setShowFooter} defaultTemplate="jetpack" />} />
        <Route path="/jim-halpert" element={<Meme setShowFooter={setShowFooter} defaultTemplate="jim" />} />
        <Route path="/joker-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="joker" />} />
        <Route path="/sad-keanu" element={<Meme setShowFooter={setShowFooter} defaultTemplate="keanu" />} />
        <Route path="/khaby-lame-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="khaby-lame" />} />
        <Route path="/kramer-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="kramer" />} />
        <Route path="/everything-the-light-touches-is-our-kingdom" element={<Meme setShowFooter={setShowFooter} defaultTemplate="light" />} />
        <Route path="/laughing-lizard" element={<Meme setShowFooter={setShowFooter} defaultTemplate="ll" />} />
        <Route path="/i-made-this-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="made" />} />
        <Route path="/member-berries" element={<Meme setShowFooter={setShowFooter} defaultTemplate="mb" />} />
        <Route path="/michael-scott-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="michael-scott" />} />
        <Route path="/midwit" element={<Meme setShowFooter={setShowFooter} defaultTemplate="midwit" />} />
        <Route path="/shut-up-and-take-my-money" element={<Meme setShowFooter={setShowFooter} defaultTemplate="money" />} />
        <Route path="/one-does-not-simply-walk-into-mordor" element={<Meme setShowFooter={setShowFooter} defaultTemplate="mordor" />} />
        <Route path="/morpheus" element={<Meme setShowFooter={setShowFooter} defaultTemplate="morpheus" />} />
        <Route path="/nails-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="nails" />} />
        <Route path="/no-idea-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="noidea" />} />
        <Route path="/overly-attached-girlfriend" element={<Meme setShowFooter={setShowFooter} defaultTemplate="oag" />} />
        <Route path="/oprah-you-get-a-car" element={<Meme setShowFooter={setShowFooter} defaultTemplate="oprah" />} />
        <Route path="/panic-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="panik-kalm-panik" />} />
        <Route path="/patrick-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="patrick" />} />
        <Route path="/philosoraptor" element={<Meme setShowFooter={setShowFooter} defaultTemplate="philosoraptor" />} />
        <Route path="/pooh-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="pooh" />} />
        <Route path="/phoebe-teaching-joey" element={<Meme setShowFooter={setShowFooter} defaultTemplate="ptj" />} />
        <Route path="/regret-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="regret" />} />
        <Route path="/remember-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="remembers" />} />
        <Route path="/right-meme" element={<Meme setShowFooter={setShowFooter} defaultTemplate="right" />} />
        <Route path="/roll-safe" element={<Meme setShowFooter={setShowFooter} defaultTemplate="rollsafe" />} />
      </Routes>
      {showFooter && <Footer />}
      <BottomBanner />
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;
